.term-condition-page {
    /* background-color: #F8FAF7; */
}

.term-condition-page a{
  color: var(--primary-176B41);
}

.term-condition-left li {
    font-family: Poppins;
    font-size: 20px;
    margin-bottom: 4px;
    cursor: pointer;
    line-height: 30px;
    color: #090909;
}

ol.term-condition-left {
    position: sticky;
    width: 100%;
    top: 120px;
}

.term-condition-left li a{
    color: var(--black090909);
}

 .green_small_text {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    font-style: normal;
    color: #176b41;
    text-transform: uppercase;
}

 .tnc_heading {
    font-family: "playfair display SemiBold";
    font-size: 36px;
    color: var(--primary-176B41) !important;
    margin-top: 40px;
    line-height: 45px;
}

.tnc-runing-text p{
  font-size: 16px ;
  color: #090909;
  line-height: 25px;
  font-family: 'Poppins';
}

.tnc-runing-text p span{
  padding-left: 24px;
  display: inline-block;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  
  border-radius: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #999; 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777; 
  
}

/* privacy policy extra css */
ul.pri-policy-page  {
  padding-left: 24px;
}

.pri-policy-page li {
  font-size: 16px;
  color: #090909;
  line-height: 25px;
  font-family: 'Poppins' !important;
}

.mail-link {
  color: var(--primary-176B41)
}